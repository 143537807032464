import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

export type NavObject = {
  url?: string
  route?: string
}

export function useURLNav() {
  const router = useRouter()

  const goToLink = (urlAddress: NavObject) => {
    if (urlAddress.url) {
      window.location.href = urlAddress.url
      return
    }

    if (urlAddress.route && urlAddress.route !== '#') {
      router.push(urlAddress.route)
      return
    }

    showUnderConstructionAlert()
  }

  const goBack = () => {
    router.go(-1)
  }

  const showUnderConstructionAlert = () => {
    const wrapper = document.createElement('span')
    wrapper.innerHTML = '<a href="#">Descubre mas de Pharus</a>'

    Swal.fire({
      title: 'Oops...',
      text: 'Sección en construcción',
      icon: 'info',
      footer: wrapper,
      confirmButtonColor: '#fc043c'
    })
  }

  return { goToLink, goBack }
}